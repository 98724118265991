import styled from 'libs/styled';
import Image from '@sportson/core-web/components/Image';
import Link, { type LinkProps } from 'components/Link';

const BadgeWrapper = styled('div')`
    display: flex;
    max-width: 64px;
    max-height: 64px;
    flex-shrink: 0;
    user-select: none;
`;

interface ImageProps {
    url: string;
    alt?: string;
    name?: string;
}

export interface TechnicalBadgeComponent {
    badgeType?: 'technical';
    image: ImageProps;
    link: LinkProps['to'];
}

export const BadgeTechnical = ({ image, link, ...rest }: TechnicalBadgeComponent) => (
    <BadgeWrapper {...rest}>
        <Link volatile to={link} noUnderline>
            {image?.url && (
                <Image
                    alt={image.alt || ''}
                    title={image.name || ''}
                    src={{
                        url: image.url,
                        width: [64, 64],
                    }}
                />
            )}
        </Link>
    </BadgeWrapper>
);
