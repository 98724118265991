import styled from '@emotion/styled';
import Text from 'components/Text';

const StandardPrice = styled(Text)`
    text-decoration: line-through;
    margin-left: 4px;
    transform: translateY(-2px);
`;

export default StandardPrice;
