import styled from 'libs/styled';
import { BadgeCommercial, type CommercialBadgeComponent } from './BadgeCommercial';
import { BadgeTechnical, type TechnicalBadgeComponent } from './BadgeTechnical';

const BadgesWrapper = styled('div')`
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
`;

interface Props {
    badges: (CommercialBadgeComponent | TechnicalBadgeComponent)[];
    className?: string;
}

export const Badges = ({ badges, className = '' }: Props) => (
    <BadgesWrapper className={className}>
        {badges.map(
            (badge) => badge.badgeType === 'technical' && <BadgeTechnical image={badge.image} link={badge.link} />,
        )}
        {badges.map(
            (badge) =>
                badge.badgeType === 'commercial' && (
                    <BadgeCommercial label={badge.label} badgeTheme={badge.badgeTheme} />
                ),
        )}
    </BadgesWrapper>
);
