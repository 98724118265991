import { inServer } from '@sportson/core-web/constants';
import AlgoliaContainer from '@sportson/core-web/containers/AlgoliaContainer';
import Events from '@sportson/core-web/libs/Events';
import { ProductEvents } from '@sportson/core-web/libs/Events/constants';
import { injectModel } from '@sportson/core-web/state';
import getQueryParams from '@sportson/core-web/utils/getQueryParams';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';

class ProductsContainer extends Component {
    /**
     * @return array [pagination, pageSize]
     */
    static paginationReset = (props) => {
        const firstPageSize = props.firstPageSize || props.pageSize;
        const { location, pagination, pageSize } = props;
        const query = getQueryParams(location);
        let newPagination = query.page ? query.page : pagination;
        let newPageSize = newPagination === 1 ? firstPageSize : pageSize;

        if (props.withLoadMore && newPagination > 1) {
            newPageSize *= newPagination;
            newPagination = 1;
        }
        return [newPagination, newPageSize];
    };

    pagination = {
        nextUrl: () => {
            const { location } = this.props;
            const query = inServer ? getQueryParams(location) : getQueryParams(window.location);

            if (!query.page) {
                query.page = 1;
            }

            query.page++;

            // todo: when nothing more to load return empty string

            return `${location.pathname}?${Object.entries(query)
                .map((q) => q.join('='))
                .join('&')}`;
        },

        // todo create prevUrl()
    };

    static propTypes = {
        application: PropTypes.object.isRequired, // this is redux store application
        attributesToRetrieve: PropTypes.array,
        categories: PropTypes.array,
        collections: PropTypes.array,
        distinct: PropTypes.bool,
        filters: PropTypes.shape({
            // this filter is set and never changed for one time.
            default: PropTypes.array,
            // This filter is the active filter that changes
            selected: PropTypes.array,
            //
            dynamic: PropTypes.array,
        }),
        firstPageSize: PropTypes.number,
        history: PropTypes.object.isRequired,
        ids: PropTypes.array,
        index: PropTypes.string,
        isDynamicFilter: PropTypes.bool,
        isPagination: PropTypes.bool,
        location: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        pageSize: PropTypes.number,
        pagination: PropTypes.number,
        render: PropTypes.func.isRequired,
        renderProps: PropTypes.object,
        responseCallback: PropTypes.func,
        ruleContexts: PropTypes.array,
        search: PropTypes.string,
        withLoadMore: PropTypes.bool,
    };

    static defaultProps = {
        attributesToRetrieve: ['*'],
        categories: [],
        collections: [],
        distinct: true,
        filters: {
            default: [],
            selected: [],
            dynamic: [],
        },
        firstPageSize: null,
        ids: [],
        index: 'products',
        isDynamicFilter: false,
        isPagination: false,
        pageSize: 24,
        pagination: 1,
        renderProps: {},
        responseCallback: null,
        ruleContexts: [],
        search: '',
        withLoadMore: false,
    };

    constructor(props) {
        super(props);
        const [pagination, pageSize] = ProductsContainer.paginationReset(props);
        const locale = props.application.site.locale.toLowerCase();

        this.state = {
            index: props.index === 'products' ? `products_${locale}` : `${props.index}`,
            pageSize,
            pagination,
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { index, location, pageSize } = props;
        const firstPageSize = props.firstPageSize || pageSize;
        const query = inServer ? getQueryParams(location) : getQueryParams(window.location);

        if (
            parseInt(query.page, 10) !== state.pagination &&
            (firstPageSize === state.pageSize || pageSize === state.pageSize)
        ) {
            state.pagination = query.page ? parseInt(query.page, 10) : 1;
            state.pageSize = state.pagination > 1 ? pageSize : firstPageSize;
        }

        if (index !== state.index) {
            const [pagination, pageSize] = ProductsContainer.paginationReset(props);
            const locale = props.application.site.locale.toLowerCase();

            state.pagination = pagination;
            state.pageSize = pageSize;
            state.index = index === 'products' ? `products_${locale}` : `${props.index}`;
        }

        return state;
    }

    shouldComponentUpdate(nextProps) {
        const { application, location, history, match, isPagination, ...props } = this.props;
        const {
            application: nextApplication,
            location: nextLocation,
            history: nextHistory,
            match: nextMatch,
            isPagination: nextIsPagination,
            ...newNextProps
        } = nextProps;

        if (
            (isPagination && location.search !== nextLocation.search && location.pathname === nextLocation.pathname) ||
            JSON.stringify(props) !== JSON.stringify(newNextProps)
        ) {
            return true;
        }

        return application.site.locale !== nextApplication.site.locale;
    }

    componentDidUpdate(prevProps) {
        const { categories, collections, filters, firstPageSize, pageSize: pageSizeProp, search } = this.props;
        if (
            JSON.stringify(prevProps.filters) !== JSON.stringify(filters) ||
            JSON.stringify(prevProps.categories) !== JSON.stringify(categories) ||
            JSON.stringify(prevProps.collections) !== JSON.stringify(collections) ||
            prevProps.search !== search
        ) {
            const [pagination, pageSize] = ProductsContainer.paginationReset(this.props, firstPageSize || pageSizeProp);

            // todo: reset container function?
            this.setState({
                pagination,
                pageSize,
            });
        }
    }

    parameters = (
        ids,
        categories,
        collections,
        filters,
        facets = ['*'],
        ruleContexts,
        offset,
        length,
        distinct,
        facetingAfterDistinct = true,
        attributesToRetrieve = ['*'],
    ) => ({
        facets,
        offset,
        length,
        ruleContexts,
        distinct,
        facetingAfterDistinct,
        filters: [
            // [`_pricelists:${application.shop_config.pricelist_id}`],
            // [`_marketplaces:${application.shop_config.market_id}`],
            ids.map((id) => `objectID:"${id}"`),
            categories.map((c) => `categories.id:"${c}"`),
            collections.map((c) => `collection.id:"${c}"`),
            ...filters,
        ].filter((i) => i.length !== 0),
        attributesToRetrieve,
    });

    getParameters = () => {
        const {
            attributesToRetrieve,
            distinct,
            ids,
            categories,
            collections,
            filters,
            application,
            ruleContexts,
            isPagination,
            ...props
        } = this.props;
        const { pagination, pageSize, index } = this.state;
        const firstPageSize = props.firstPageSize || props.pageSize;

        let { search } = this.props;

        const parameters = [];

        const locale = application.site.locale.toLowerCase();

        const page = isPagination ? (isNaN(pagination) || pagination <= 1 ? 0 : pagination - 1) : 0;
        const offset = page === 0 ? 0 : firstPageSize + pageSize * (page - 1);

        // todo: only get default once per changed filter default
        // default query
        try {
            search = decodeURI(search);
        } catch (e) {
            // console.error(e, 'Get params');
        }

        parameters.push({
            indexName: index,
            query: search,
            params: this.parameters(
                ids,
                categories,
                collections,
                filters.default,
                ['*'],
                ruleContexts,
                offset,
                page === 0 ? firstPageSize : pageSize,
                distinct,
                distinct,
                attributesToRetrieve,
            ),
        });

        // selected query
        if (search !== '' || filters.selected.length > 0) {
            const selected = filters.default.concat(filters.selected);

            parameters.push({
                indexName: index,
                query: search,
                params: this.parameters(
                    ids,
                    categories,
                    collections,
                    selected,
                    ['*'],
                    ruleContexts,
                    offset,
                    page === 0 ? firstPageSize : pageSize,
                    distinct,
                    distinct,
                    attributesToRetrieve,
                ),
            });
        }

        return parameters;
    };

    getDynamicParameters = () => {
        const {
            attributesToRetrieve,
            distinct,
            ids,
            categories,
            collections,
            filters,
            application,
            ruleContexts,
            isPagination,
            ...props
        } = this.props;
        const { pagination, pageSize, index } = this.state;
        const firstPageSize = props.firstPageSize || props.pageSize;

        let { search } = this.props;

        const parameters = [];

        const locale = application.site.locale.toLowerCase();

        const page = isPagination ? (isNaN(pagination) || pagination <= 1 ? 0 : pagination - 1) : 0;
        const offset = page === 0 ? 0 : firstPageSize + pageSize * (page - 1);

        // todo: only get default once per changed filter default
        // default query
        try {
            search = decodeURI(search);
        } catch (e) {
            // console.error(e, 'Get params');
        }

        parameters.push({
            indexName: index,
            query: search,
            params: this.parameters(
                ids,
                categories,
                collections,
                filters.default,
                ['*'],
                ruleContexts,
                offset,
                page === 0 ? firstPageSize : pageSize,
                distinct,
                distinct,
                attributesToRetrieve,
            ),
        });

        // selected query
        if (search !== '' || filters.selected.length > 0) {
            const selected = filters.default.concat(filters.selected);

            parameters.push({
                indexName: index,
                query: search,
                params: this.parameters(
                    ids,
                    categories,
                    collections,
                    selected,
                    ['*'],
                    ruleContexts,
                    offset,
                    page === 0 ? firstPageSize : pageSize,
                    distinct,
                    distinct,
                    attributesToRetrieve,
                ),
            });
        }

        (filters.dynamic || []).forEach((filterParameter) => {
            // selected query
            if (search !== '' || filterParameter.filters.length > 0) {
                const selected = filters.default.concat(filterParameter.filters);

                parameters.push({
                    indexName: index,
                    query: search,
                    params: this.parameters(
                        ids,
                        categories,
                        collections,
                        selected,
                        filterParameter.facets,
                        ruleContexts,
                        offset,
                        page === 0 ? firstPageSize : pageSize,
                        distinct,
                        distinct,
                        attributesToRetrieve,
                    ),
                });
            }
        });

        return parameters;
    };

    responseCallback = (response) => {
        if (!response) {
            return null;
        }

        const { responseCallback, pageSize, filters, firstPageSize } = this.props;
        const { pagination } = this.state;

        const defaultProducts = response[0];
        const products = response[1] || {};

        // take products and use defaultProducts as fallback
        // this is used when there is no props.filters.selected
        const get = (d, p, k) => (p[k] !== undefined ? p[k] : d[k]);

        const offset = get(defaultProducts, products, 'offset');
        const length = get(defaultProducts, products, 'length');
        const newPageSize = get(defaultProducts, products, 'hitsPerPage');
        const nbHits = get(defaultProducts, products, 'nbHits');
        const hitsCount = nbHits;
        const newPagination = newPageSize > pageSize ? newPageSize / pageSize : pagination;
        const page = Math.ceil(offset / length);
        const pageCount =
            firstPageSize > 0 ? Math.ceil((hitsCount - firstPageSize) / pageSize) + 1 : Math.ceil(hitsCount / pageSize);

        try {
            Events.trigger(ProductEvents.IMPRESSIONS, {
                products: get(defaultProducts, products, 'hits'),
                offset: page > 0 ? page * newPageSize : 0,
            });
        } catch (e) {
            //
        }

        let facets = defaultProducts?.facets;
        let facetsStats = defaultProducts?.facets_stats;

        if (response.length > 2 && filters.dynamic?.length) {
            response.slice(2).forEach((res) => {
                facets = get(defaultProducts, products, 'facets');
                facetsStats = get(defaultProducts, products, 'facets_stats');

                const { facets: responseFacets, facets_stats: responseFacetsStats } = res;

                Object.keys(responseFacets).forEach((key) => {
                    facets[key] = responseFacets[key];
                    if (responseFacetsStats && facetsStats[key] && responseFacetsStats[key]) {
                        facetsStats[key] = responseFacetsStats[key];
                    }
                });
            });
        } else if (filters.dynamic?.length > 0) {
            const key = filters.dynamic[0].facets;
            const foundFacet = facets[key];
            const foundFacetState = facetsStats[key];

            facets = get(defaultProducts, products, 'facets');
            facetsStats = get(defaultProducts, products, 'facets_stats');

            facets[key] = foundFacet;
            facetsStats[key] = foundFacetState;
        }

        const data = {
            filters: facets,
            filter_stats: facetsStats,
            products: get(defaultProducts, products, 'hits'),
            productsCount: get(defaultProducts, products, 'hits').length,
            page,
            offset,
            pageSize: length,
            pageCount,
            hitsCount,
            hasMore: get(defaultProducts, products, 'hasMore'),
            pagination: this.pagination,
            queryId: defaultProducts.queryID,
        };

        this.setState({
            pageSize: length,
            pagination: newPagination,
        });

        return responseCallback ? responseCallback(data) : data;
    };

    render() {
        const { render, renderProps, isDynamicFilter } = this.props;

        return (
            <AlgoliaContainer
                parameters={isDynamicFilter ? this.getDynamicParameters() : this.getParameters()}
                responseCallback={this.responseCallback}
                render={({ application, ...rest }) => render({ ...rest, renderProps })}
            />
        );
    }
}

export default withRouter(injectModel('application')(ProductsContainer));
