import React, { PureComponent } from 'react';
import md5 from 'blueimp-md5';
import PropTypes from 'prop-types';
import { MultiQuery, Search } from '@sportson/core-web/libs/Algolia-v2';
import { PromisesConsumer, addPromise } from '@sportson/core-web/contexts/promises-context';

/*
    Container only relies on context to get saved data from a session (resets after refresh of page)
    Therefore we have completely removed the internal state for promise and response

    addPromise is now called both server- and clientside to prevent fetching of same data multiple times during a session
    Since we always call addPromise in getPromise and addPromise uses hooks,
    getPromise is also removed from componentDidUpdate because hooks can't be used outside render of component
*/

class AlgoliaContainer extends PureComponent {
    static generateUuid = (parameters) => md5(`algoliaContainer_${JSON.stringify(parameters)}`);

    static propTypes = {
        parameters: PropTypes.arrayOf(
            PropTypes.shape({
                indexName: PropTypes.string,
                query: PropTypes.string,
                params: PropTypes.shape({
                    facets: PropTypes.array,
                    facetingAfterDistinct: PropTypes.bool,

                    // Important to pay attention to the structure
                    filters: PropTypes.arrayOf(
                        // AND
                        PropTypes.arrayOf(
                            // OR
                            PropTypes.string,
                        ),
                    ),
                    ruleContexts: PropTypes.array,
                    restrictSearchableAttributes: PropTypes.arrayOf(PropTypes.string),
                    // use this together
                    page: PropTypes.number,
                    hitsPerPage: PropTypes.number,
                    attributesToRetrieve: PropTypes.arrayOf(PropTypes.string),
                    // use this together
                    offset: PropTypes.number,
                    length: PropTypes.number,
                }),
            }),
        ),
        render: PropTypes.func,
        renderProps: PropTypes.object,
        // this needs to return response data
        responseCallback: PropTypes.func,
    };

    static defaultProps = {
        parameters: [],
        render: () => {},
        renderProps: {},
        responseCallback: (i) => i,
    };

    getPromise = (state = {}) => {
        const { parameters } = this.props;
        const { promises, responses } = state;
        let promise = null;
        const uuid = AlgoliaContainer.generateUuid(parameters);

        if (responses && responses[uuid]) {
            return new Promise((resolve) => {
                resolve();
            });
        }
        if (!promises || !promises[uuid]) {
            // eslint-disable-next-line no-async-promise-executor -- Not sure why this is here and this should be fixed!
            promise = new Promise(async (resolve, reject) => {
                let responses = null;
                if (parameters.length === 1) {
                    const param = parameters[0];
                    responses = await Search(param.indexName, param.query, param.params);
                } else {
                    responses = await MultiQuery(parameters);
                }

                if (responses.results || responses.hits) {
                    const results = responses.results || (responses.hits && responses);
                    resolve(results);
                }

                reject();
            });

            // Add promise to context
            addPromise(uuid, promise, '/');
        } else {
            promise = promises[uuid].promise;
        }

        return promise;
    };

    render() {
        const { parameters, render, renderProps, responseCallback } = this.props;
        /*
            Instead of getting id from this.uuid we create uuid here to get correct value directly after re-renders
            It will still match with the uuid which saves the response in getPromise
        */
        const uuid = AlgoliaContainer.generateUuid(parameters);

        return (
            <PromisesConsumer>
                {({ state }) => {
                    if (state.responses[uuid] && state.responses[uuid].response) {
                        return render({
                            promise: this.getPromise(state),
                            response: responseCallback(state.responses[uuid].response),
                            renderProps,
                        });
                    }

                    return render({ promise: this.getPromise(state), renderProps });
                }}
            </PromisesConsumer>
        );
    }
}

export default AlgoliaContainer;
