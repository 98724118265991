import Text from 'components/Text';
import styled from 'libs/styled';
import { above } from '@sportson/core-web/utils/mediaqueries';
import { type ProductFlag } from 'components/product/types';

const BadgeWrapper = styled('div')`
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    flex-wrap: wrap;
    user-select: none;
    border-radius: 4px;

    border-radius: var(--corner-radius-round, 4px);
    background-color: var(--background-color-default);
    border: 1px solid var(--color-neutrals-200);
    color: var(--color-neutrals-500);

    ${above['tablet.sm']} {
        padding: 8px 12px;
    }

    ${({ badgeTheme }) =>
        badgeTheme === 'campaign' &&
        `
        background-color: var(--color-brand-yellow);
        border-color: var(--color-brand-yellow);
        color: var(--color-base-black);
    `}

    ${({ badgeTheme }) =>
        badgeTheme === 'sale' &&
        `
        background-color: var(--color-semantic-red);
        border-color: var(--color-semantic-red);
        color: var(--color-base-white);
    `}
`;

type BadgeThemes = 'default' | 'campaign' | 'sale';
export interface CommercialBadgeComponent {
    badgeType?: 'commercial';
    label: string;
    badgeTheme: BadgeThemes;
}

export const BadgeCommercial = ({ label, badgeTheme, ...rest }: CommercialBadgeComponent) => (
    <BadgeWrapper badgeTheme={badgeTheme} {...rest}>
        <Text as="span" typography="UI/12_100_0_450_uppercase">
            {label}
        </Text>
    </BadgeWrapper>
);
