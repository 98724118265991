import styled, { stylesToResponsiveCssPropsByKeys } from '@grebban/style-system-react';
import brandingTypography, { type Typography } from 'config/branding/typography';
import { type BoxButtonTheme, type BoxButtonSize, STYLES, THEMES, SIZES } from 'config/branding/boxButtons';
import CoreButton from '@grebban/react-core-components/Button';
import React from 'react';

const StyledButton = styled(CoreButton)`
    border-radius: 4px;
    background-color: ${({ buttonTheme }) => buttonTheme.backgroundColor};
    color: ${({ buttonTheme }) => buttonTheme.color};
    border: ${({ buttonTheme }) => buttonTheme.border};
    white-space: pre;
    text-transform: ${({ textTransform }) => textTransform ?? 'none'};
    padding: ${({ size }) => size.padding};
    gap: ${({ size }) => size.gap};

    svg:not(.loading-icon) path {
        fill: ${({ buttonTheme }) => buttonTheme.color};
        transition: stroke var(--transition-primary);
    }

    &:hover {
        background-color: ${({ buttonTheme }) => buttonTheme.hover?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.hover?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.hover?.border ?? buttonTheme.border};

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.hover?.color ?? buttonTheme.color};
        }
    }

    &:active,
    &[data-is-active='true'] {
        background-color: ${({ buttonTheme }) => buttonTheme.active?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.active?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.active?.border ?? buttonTheme.border};

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.active?.color ?? buttonTheme.color};
        }
    }

    &:disabled {
        background-color: ${({ buttonTheme }) => buttonTheme.disabled?.backgroundColor ?? buttonTheme.backgroundColor};
        color: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
        border: ${({ buttonTheme }) => buttonTheme.disabled?.border ?? buttonTheme.border};
        pointer-events: none;
        cursor: not-allowed;

        svg:not(.loading-icon) path {
            fill: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
        }

        &:hover {
            background-color: ${({ buttonTheme }) =>
                buttonTheme.disabled?.backgroundColor ?? buttonTheme.backgroundColor};
            color: ${({ buttonTheme }) => buttonTheme.disabled?.color ?? buttonTheme.color};
            border: ${({ buttonTheme }) => buttonTheme.disabled?.border ?? buttonTheme.border};
        }
    }
`;

export interface ButtonProps extends Record<string, unknown> {
    children: React.ReactNode | typeof React.Children | string;
    disabled?: boolean;
    fullWidth?: boolean;
    isActive?: boolean;
    loading?: boolean;
    theme?: BoxButtonTheme;
    size?: BoxButtonSize;
    url?: string;
    typography?: Typography | Array<Typography | '' | null>;
}

const Button = ({
    children,
    disabled = false,
    fullWidth = false,
    isActive = false,
    loading = false,
    theme = 'outlined',
    url = '',
    typography = 'UI/16_100_0_450',
    size = 'small',
    ...rest
}: ButtonProps) => (
    <StyledButton
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- The style package is actually incorrect typed, it should be "string | Array<string | null>" and not only strings.
        // @ts-ignore
        {...stylesToResponsiveCssPropsByKeys(typography, brandingTypography)}
        type="button"
        style={STYLES}
        disabled={disabled}
        buttonTheme={THEMES[theme]}
        size={SIZES[size]}
        to={url}
        width={fullWidth ? '100%' : 'fit-content'}
        data-is-active={isActive}
        {...rest}
    >
        {children}
    </StyledButton>
);

export default Button;
