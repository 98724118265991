import { type TechnicalBadgeComponent } from '../../../Views/Product/ProductInformation/BadgeTechnical';
import { type CommercialBadgeComponent } from '../../../Views/Product/ProductInformation/BadgeCommercial';

export const filterProductBadges = (productFlagIdSeed, displayableFlags) => {
    const commercialBadges: CommercialBadgeComponent[] = [];
    const technicalBadges: TechnicalBadgeComponent[] = [];

    if (!displayableFlags || !productFlagIdSeed) {
        return { commercialBadges, technicalBadges };
    }

    const filteredFlags = displayableFlags.filter((displayFlag) => productFlagIdSeed.includes(displayFlag.flag.id));

    filteredFlags.forEach((displayFlag) => {
        if (displayFlag.badgeType === 'technical') {
            technicalBadges.push({
                badgeType: displayFlag.badgeType,
                image: displayFlag.technicalBadge.badge.badgeImage.image,
                link: displayFlag.technicalBadge.badge?.badgeLink,
            });
        } else {
            commercialBadges.push({
                badgeType: displayFlag.badgeType,
                badgeTheme: displayFlag.commercialBadge.badge.badgeTheme,
                label:
                    displayFlag.commercialBadge.badge.label === 'default' && displayFlag.flag
                        ? displayFlag.flag.name
                        : displayFlag.commercialBadge.badge.label,
            });
        }
    });

    return { commercialBadges, technicalBadges };
};
