import algoliasearch from 'algoliasearch';
import cloneDeep from '@sportson/core-web/utils/cloneDeep';

const APPLICATION_ID = process.env.REACT_APP_ALGOLIA_APPLICATION_ID;
const API_KEY = process.env.REACT_APP_ALGOLIA_API_KEY;
const PREFIX = process.env.REACT_APP_ALGOLIA_PREFIX || '';
const POST_PREFIX = process.env.REACT_APP_ALGOLIA_POSTS_PREFIX || '';

const getIndexName = (indexName, isPostIndex) => (isPostIndex ? POST_PREFIX + indexName : PREFIX + indexName);
const getClient = () => algoliasearch(APPLICATION_ID, API_KEY);

const handleResult = (result) => {
    const newResult = cloneDeep(result);

    if (newResult) {
        if (typeof newResult.page !== 'undefined') {
            newResult.hasMore = (newResult.page + 1) * newResult.hitsPerPage < newResult.nbHits;
        } else {
            newResult.hasMore = newResult.offset + newResult.length < newResult.nbHits;
        }
    }

    return newResult;
};

const handleParams = (params) => {
    const newParams = cloneDeep(params);

    if (newParams.filters !== undefined) {
        newParams.filters = newParams.filters
            .map((filter) => {
                filter = filter.map((row) => {
                    const [name, value] = row.split(':');
                    if (value) {
                        if (value.charAt(0) === '!' || value.charAt(1) === '!') {
                            return `NOT "${name}":${value.replace('!', '')}`;
                        }
                        return `"${name}":${value}`;
                    }
                    return row;
                });
                return `(${filter.join(' OR ')})`;
            })
            .join(' AND ');
    }

    if (newParams.page !== undefined) {
        newParams.page--;
    }

    newParams.clickAnalytics = true;

    return newParams;
};

export const Search = (indexName, query, params) =>
    new Promise((resolve, reject) => {
        try {
            const isPostIndex = indexName.includes('posts');
            const client = getClient();
            const index = client.initIndex(getIndexName(indexName, isPostIndex));
            index.search(query, handleParams(params), (error, result) => {
                if (error) {
                    reject(error);
                }

                resolve({ results: [handleResult(result)] });
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });

export const MultiQuery = (queries) => {
    let searchQueries = JSON.parse(JSON.stringify(queries));
    searchQueries = searchQueries.map((q) => {
        const isPostIndex = q.indexName.includes('posts');
        q.indexName = getIndexName(q.indexName, isPostIndex);
        return q;
    });

    return new Promise((resolve, reject) => {
        try {
            const client = getClient();

            searchQueries = searchQueries.map((q) => {
                q.params = handleParams(q.params);
                return q;
            });

            client.search(searchQueries, (error, result) => {
                if (error) {
                    return reject(error);
                }

                result.results = result.results.map((r) => handleResult(r));

                resolve(result);
            });
        } catch (error) {
            console.error(error);
            reject(error);
        }
    });
};
