import React, { useState, useEffect } from 'react';

import styled from '@grebban/style-system-react';
import useAppDispatch from '@sportson/core-web/hooks/useAppDispatch';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import { addCompareProduct, removeCompareProduct } from '@sportson/core-web/state/ecommerce/compare';
import { isProductInComparisonLocalStorage } from '@sportson/core-web/utils/compareProducts';
import Compare from 'assets/media/icons/Compare';
import TextStyles from 'components/Text';
import { useTranslation } from 'react-i18next';
import { above } from '@sportson/core-web/utils/mediaqueries';

const ToggleInput = styled('input')`
    height: 0;
    width: 0;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
`;

const ToggleSwitch = styled('label')`
    position: relative;
    display: inline-flex;
    gap: 12px;
    align-items: center;
    user-select: none;
    cursor: pointer;
    z-index: 9;
    color: ${(props: { checked: boolean }) =>
        props.checked ? 'var(--toggleswitch-checked-handle-color)' : 'var(--toggleswitch-color)'};

    &:hover {
        color: var(--toggleswitch-hover-color);
    }
`;

const Toggle = styled('div')`
    position: relative;
    border-radius: 100px;
    background-color: var(--toggleswitch-background-color);
    transition: 0.15s;
    user-select: none;
    flex: 0 0 auto;
`;

const Handle = styled('div')`
    position: absolute;
    display: flex;
    height: 100%;
    aspect-ratio: 1/1;
    left: 0;
    top: 0;
    border-radius: 50%;
    background-color: var(--toggleswitch-handle-background-color);
    border: 1px solid var(--toggleswitch-handle-border-color);
    transition: 0.15s;
    z-index: 1;
    align-items: center;
    justify-content: center;

    &.iscompared {
        left: calc(100% - ${(props: { toggleHeight: string }) => props.toggleHeight});
        background-color: var(--toggleswitch-checked-handle-background-color);
        color: var(--toggleswitch-checked-handle-color);
        border: 1px solid var(--toggleswitch-checked-handle-border-color);
    }
`;

const Description = styled(TextStyles)`
    word-wrap: nowrap;
    display: ${({ hideText }) => (hideText ? 'none' : 'block')};

    ${above['tablet.sm']} {
        display: block;
    }
`;

export type ToggleComparisonProps = {
    productId: string;
    image: string;
    name: string;
    size?: 'small' | 'medium' | 'large';
    hideText?: boolean;
};

const sizeMap = {
    small: {
        height: '24px',
        width: '40px',
    },
    medium: {
        height: '32px',
        width: '48px',
    },
    large: {
        height: '40px',
        width: '56px',
    },
};

const ToggleComparison = ({ size = 'medium', productId, image, name, hideText }: ToggleComparisonProps) => {
    const compareProduct = {
        id: productId,
        image,
        name,
    };

    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const compareProducts = useAppSelector(({ ecommerce }) => ecommerce.compare.products);
    const isCompared = compareProducts.some((product) => product.id === productId);
    const disabled = compareProducts.length >= 4 && !isCompared;
    const [compared, setCompared] = useState(isCompared);

    const handleToggleComparison = () => {
        if (isCompared) {
            dispatch(removeCompareProduct(compareProduct));
        } else {
            dispatch(addCompareProduct(compareProduct));
        }
        setCompared(!compared);
    };

    const monitorCompareStatus = () => {
        const isInLocalStorage = isProductInComparisonLocalStorage(compareProduct);
        setCompared(isInLocalStorage);
    };

    useEffect(() => {
        setCompared(isCompared);
    }, [isCompared]);

    useEffect(() => {
        window.addEventListener('storage', monitorCompareStatus);
        return () => {
            window.removeEventListener('storage', monitorCompareStatus);
        };
    }, []);

    return (
        <ToggleSwitch htmlFor={`togglecomparison-${productId}`} checked={isCompared}>
            <ToggleInput
                id={`togglecomparison-${productId}`}
                checked={compared}
                disabled={disabled}
                type="checkbox"
                onChange={() => handleToggleComparison()}
            />
            <Toggle width={sizeMap[size].width} height={sizeMap[size].height}>
                <Handle
                    className={compared && 'iscompared'}
                    toggleWidth={sizeMap[size].width}
                    toggleHeight={sizeMap[size].height}
                >
                    <Compare color={compared ? 'var(--color-brand-yellow)' : 'var(--toggleswitch-color)'} />
                </Handle>
            </Toggle>
            <Description as="div" typography="UI/14_100_0_450" hideText={hideText}>
                {t('compare.compare')}
            </Description>
        </ToggleSwitch>
    );
};

export default ToggleComparison;
