import FormatPrice from '@sportson/core-web/components/FormatPrice';
import Link from '@sportson/core-web/components/Link';
import { useTranslation } from 'react-i18next';
// import StockStatus from '@sportson/core-web/components/StockStatus';
import Row from '@sportson/core-web/App/layouts/Row';
import Image from '@sportson/core-web/components/Image';
import tracking from '@sportson/core-web/components/Tracking';
import useAppSelector from '@sportson/core-web/hooks/useAppSelector';
import Events from '@sportson/core-web/libs/Events';
import { ProductEvents } from '@sportson/core-web/libs/Events/constants';
import { generateSrcSet } from '@sportson/core-web/libs/image';
import { above } from '@sportson/core-web/utils/mediaqueries';
import { Badges } from 'Views/Product/ProductInformation/Badges';
import Text from 'components/Text';
import ToggleComparison from 'components/inputs/ToggleComparison';
import { filterProductBadges } from 'components/productPage/utils/filterProductBadges';
import { STORM_IMAGE_URL } from 'config/constants';
import styled from 'libs/styled';
import { type WordpressACFNorceFlagDetailedData } from 'libs/wordpress/types/acf/fields';
import { useHistory } from 'react-router-dom';
import StandardPrice from './StandardPrice';

const CardWrapper = styled('div')``;

const TechnicalBadgesWrapper = styled('div')`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;

    .technical-badges > div {
        max-width: 48px;
        max-height: 48px;
    }

    ${above['tablet.sm']} {
        .technical-badges > div {
            max-width: 64px;
            max-height: 64px;
        }
    }
`;

const CardPanel = styled('div')`
    background-color: var(--productcard-image-background-color);
    position: relative;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    aspect-ratio: 48/67;
    justify-content: space-between;
`;

const CardImageLink = styled(Link)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 8px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 8px 10px;

    ${above['tablet.lg']} {
        padding: 8px;
    }
`;

const CardPanelHeader = styled('div')`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    ${above['tablet.lg']} {
        padding: 8px;
    }
`;

const CardPanelHeaderToggles = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
`;

const CardPanelFooter = styled('div')`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    ${above['tablet.lg']} {
        padding: 16px;
    }
`;

const CardFooter = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 16px;
`;

const ImageWrapper = styled('div')`
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    aspect-ratio: 287/325;

    img {
        width: 100%;
        height: 100%;
        max-height: 100%;
        object-fit: contain;
        display: block;
        max-width: 100%;
        mix-blend-mode: multiply;

        /* For Safari - mix-blend-mode doesn't work */
        transform: translate3d(0, 0, 0);
    }

    ${above['tablet.sm']} {
        padding: 8px 30px;
    }
`;

const ProductVariants = styled('div')`
    display: flex;
    gap: 4px;
`;

const VariantDot = styled('div')`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    border: 1px solid var(--color-neutrals-200);
    background-color: ${(props: { color: string }) => props.color};
`;

const StyledStandardPrice = styled(StandardPrice)`
    transform: translateY(2px);
`;

export interface ProductCardProps extends Record<string, unknown> {
    product: ProductCardProduct;
    gridColumn?: string;
}

export type ProductCardProduct = {
    manufacturer: { name: string };
    productId: number;
    objectID: string;
    image: string;
    imageKey: string;
    price: number;
    stock: number;
    name: string;
    uniqueName: string;
    variantColors?: Array<{ name: string; color: string }>;
    pricing: any;
    url: string;
    flags?: WordpressACFNorceFlagDetailedData[];
};

export const ProductCard = ({ product, gridColumn = '', ...rest }: ProductCardProps) => {
    const { t } = useTranslation();
    const { url, variantColors = [], image } = product;
    const productUrl = `${url}`;
    const hasVariants = !!product.variantColors?.length;
    const currencyCode = useAppSelector(({ application }) => application?.site?.currency?.code) || '';
    const productFlagIdSeed = product?.flags?.map((flag) => flag.id) ?? [];
    const displayableBadgeFlags = useAppSelector(
        ({ application }) => application.config.options?.productPage?.productBadges ?? [],
    );
    const { commercialBadges, technicalBadges } = filterProductBadges(productFlagIdSeed, displayableBadgeFlags);
    const history = useHistory(); // Initialize useHistory hook

    const widths = [140, 304, 336, 400, 480];

    const currentPrice = product.pricing.pricelists.web.incVat;
    const standardPrice = product.pricing.standard.incVat;
    const onSale = currentPrice < standardPrice;
    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        try {
            Events.trigger(ProductEvents.CLICK, {
                eventId: tracking.getUniqueId(),
                product: {
                    item_id: product.objectID,
                    item_name: product.name,
                    price: currentPrice,
                    item_brand: product.manufacturer.name,
                    item_variant: product.objectID || '',
                    quantity: '',
                },
                currency: currencyCode,
            });
        } catch (e) {
            //
        }
        history.push(url);
    };
    const formatProps = {
        separateWith: ' ',
        render: (price: string) => t('price.with_currency_alt', { price }),
    };

    const imageSrc = image ? `${STORM_IMAGE_URL}${image}` : '';

    const srcSet = generateSrcSet(imageSrc, widths);
    return (
        <CardWrapper gridColumn={gridColumn} {...rest}>
            <CardPanel>
                <CardPanelHeader>
                    <CardPanelHeaderToggles>
                        <ToggleComparison
                            size="medium"
                            productId={product.objectID.toString()}
                            image={image}
                            name={product.name}
                        />
                    </CardPanelHeaderToggles>
                    <TechnicalBadgesWrapper gap="8px" width="auto">
                        {technicalBadges?.length > 0 && (
                            <Badges badges={technicalBadges} className="technical-badges" />
                        )}
                    </TechnicalBadgesWrapper>
                </CardPanelHeader>
                <CardImageLink to={productUrl} onClick={handleClick}>
                    <ImageWrapper>
                        {imageSrc ? (
                            <Image
                                src={{ url: imageSrc, width: widths }}
                                srcSet={srcSet}
                                alt={product.name}
                                title={product.name}
                            />
                        ) : (
                            <Text typography="UI/12_100_0_350">{t('image_not_available')}</Text>
                        )}
                    </ImageWrapper>
                </CardImageLink>
                <CardPanelFooter>
                    {!!hasVariants && (
                        <ProductVariants>
                            {variantColors
                                ?.slice(0, 3)
                                .map((variant, index) => (
                                    <VariantDot key={variant.color.replace('#', '')} color={variant.color} />
                                ))}
                            {variantColors?.length > 3 && (
                                <Text typography="UI/12_100_0_450">+{variantColors.length - 3}</Text>
                            )}
                        </ProductVariants>
                    )}
                    {/* TODO: enable when we have a count */}
                    {/* <Text typography="UI/12_100_0_450" color="var(--color-neutrals-400)">
                        {sizeVariants > 1
                            ? t('product_card.X_sizes', { count: sizeVariants })
                            : t('product_card.single_size')}
                    </Text> */}
                </CardPanelFooter>
            </CardPanel>

            <CardFooter>
                <Text color="var(--color-neutrals-400)" typography="UI/12_100_0_450_uppercase">
                    {product.manufacturer.name}
                </Text>
                <Text typography="UI/14_100_0_450">{product.name}</Text>
                <Row gap="8px">
                    <Text
                        typography="UI/14_100_0_450"
                        color={onSale ? 'var(--text-color-sale)' : 'var(--color-base-black)'}
                    >
                        <FormatPrice {...formatProps} price={currentPrice} isRounded skipDecimals />
                    </Text>
                    {onSale && (
                        <StyledStandardPrice typography="UI/12_100_0_450">
                            <FormatPrice {...formatProps} price={standardPrice} isRounded skipDecimals />
                        </StyledStandardPrice>
                    )}
                </Row>
                {commercialBadges?.length > 0 && <Badges badges={commercialBadges} />}
            </CardFooter>
        </CardWrapper>
    );
};
