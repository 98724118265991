import { useSelector } from 'react-redux';
import i18n from 'i18next';
import PropTypes from 'prop-types';

/**
 * "pollyfill" for toFixed since it breaks in some JS versions.
 * For exmaple: (14.995).toFixed(2) becomes 14.99 instead of 15.
 * https://www.sitepoint.com/number-tofixed-rounding-errors-broken-but-fixable/
 *
 * @param {number} n - number to be fixed
 * @param {number} d - number of decimals
 * @param {string} separator - separator type
 */
export const toFixedCurrency = (n, d, separator = ',') => {
    const split = n.toString().split('.');

    if (split[1] && split[1].length === 1) {
        split[1] += '0';
    }

    const number = +(!split[1] ? split[0] : `${split.join('.')}1`);
    return number.toFixed(d).replace('.', separator);
};

const memo = {};

/**
 * Returns formatted price that auto adds vat
 *
 * @param {boolean} [isRounded=false] - If price should be rounded or not.
 * @param {string} [separateWith] - String that will separate number, ex: 1.000 if '.', 1 000 if ' '
 * @param {number} price - Price excluding VAT
 * @param {func} [render] - Render func with formatted price as prop
 * @param {number} [vatRate=1] Price is multiplied with this if showVat is set.
 * @param {number} [giftCardAmount=0] - Amount to be deducted by giftcard.
 * @param {boolean} [sendPriceAsNumber=false] - Will return object with price as int and string if true. Will only return string if false.
 * @return {string|object}
 *
 * @example
 *
 *      <FormatPrice
 *          isRounded
 *          separateWith="."
 *          price={totalPricing.priceExVat}
 *          vatRate={totalPricing.vatRate}
 *          render=(price => t('price_with_currency', { price }))
 *      />
 */

const FormatPrice = ({
    isRounded = false,
    separateWith = ' ',
    price,
    render = (price) => i18n.t('price.with_currency', { price }),
    vatRate = 1,
    giftCardAmount = 0,
    sendPriceAsNumber = false,
    skipDecimals = false,
}) => {
    const key = `${+isRounded}${separateWith || ''}_${price}_${vatRate}_${giftCardAmount}`;

    const totalPrice = price * vatRate - giftCardAmount;

    if (!memo[key]) {
        let p = totalPrice;
        const decimals = skipDecimals ? 0 : 2;
        p = toFixedCurrency(p, decimals);

        if (separateWith) {
            const splitPrice = p.toString().split('.');
            splitPrice[0] = splitPrice[0].replace(/\B(?=(\d{3})+(?!\d))/g, separateWith);
            p = splitPrice.join('.');
        }

        memo[key] = p;
    }

    const memoizedPrice = memo[key];

    if (sendPriceAsNumber) {
        return render ? render(memoizedPrice, totalPrice) : { price, priceAsNumber: totalPrice };
    }
    return render ? render(memoizedPrice) : memoizedPrice;
};

FormatPrice.propTypes = {
    giftCardAmount: PropTypes.number,
    isRounded: PropTypes.bool,
    price: PropTypes.number.isRequired,
    render: PropTypes.func,
    separateWith: PropTypes.string,
    vatRate: PropTypes.number,
    sendPriceAsNumber: PropTypes.bool,
    skipDecimals: PropTypes.bool,
};

export default FormatPrice;
