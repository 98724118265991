import { useRef } from 'react';

// Mainly used in conjunction with the ProductContainer/FilterContainer to save the response between renders

const useSaveResponse = () => {
    // Create a ref to save the response between renders
    const savedResponse = useRef({});

    // Check response.key against savedResponse.key
    // Update the component if any of the key's values differs between response and savedResponse
    const updateIsNecessary = (response, keys) =>
        keys.some(
            (key) => response[key] && JSON.stringify(response[key]) !== JSON.stringify(savedResponse.current[key]),
        );

    const saveResponse = (
        response,
        keys = ['articles', 'products', 'hits', 'filters', 'selectedFilters', 'sorts', 'posts'],
    ) => {
        // Check if response has changed by key
        if (response && updateIsNecessary(response, keys)) {
            // Update response if a change is detected
            savedResponse.current = response;
        }
    };

    return [savedResponse, saveResponse];
};

export default useSaveResponse;
